.view {
    width: 100vw;
    height: 100vh;
    position: relative;

    .examples {
        width: 70%;
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        flex-direction: row;
        position: fixed;
        z-index: 100;
        background-color: rgba(0,0,0,0.34);

        h5 {
            padding: 0;
            cursor: pointer;
            padding: 5px 15px;
            display: block;
            color: #fff;
        }
    }

    .loadBlock {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 50;
        display: flex;
        justify-content: center;
        align-items: center;

        .loadLineBlock {
            width: 40%;
            height: 40px;
            position: relative;
            background-color: rgba(0,0,0,0.44);

            .loadLine {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: orange;

            }
        }
    }
}