$blue: #146fd1;

.product-paper {
    width: 100%;
    height: 100%;
    overflow: auto !important;
}

.eyeButton {
    cursor: pointer;

    z-index: 99;
    width: 45px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    display: grid;
    place-items: center;
    color: $blue;
    font-size: 26px;
}

.controllButtons {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.socialContent {
    width: 100%;

    .socialButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin: 15px 0;

        span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            cursor: pointer;

            img {
                width: 40px;
                height:  auto;
            }

            p {
                margin: 5px 0px 0px;
                font-size: 11px;
            }
        }
    }

    .socialUrl {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: #323232;
        box-sizing: border-box;
        border-radius: 8px;

        span {
            color: #fff;
            max-width: 310px;
            word-break: break-all;
        }

        .socialUrlButton {
            color: #fff;
            margin-left: 10px;
            border-radius: 10px;
            cursor: pointer;
            font-size: 12px;
            max-width: 100px;

            span {
                width: 100%;
            }
        }
    }
}

.copyBlock {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 199999;
    color: #fff;
    opacity: .5;

    img {
        position: relative;
        left: 1px;
    }
}