.loadingImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(var(--app-height) - 150px);
    gap: 0.5rem;

    .imgContainer {
        width: 70px;
        height: 70px;
    }
}

.loadingBlinkImg {
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    width: 70px;
    height: 70px;
}

.loadingText {
    font-weight: 500;
    font-size: 25px;
}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
        transform: scale(0.9, 1.1) translateY(-35px);
    }
    50% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}
