

@media screen 
and (max-width: 992px) {

  .modal-mail-block-content {
    width: 85%;
  }
  .modal-mail-block-content input {
    width: 88%;
  }
  .modal-mail-block-content input[type="submit"] {
    width: 100%;
  }
  
	.plan-apartment-block > img,
  .plan-apartment-block-content > img {
		max-width: 150px;
		max-height: 200px;
	}

  .plan-apartment-block-content > a {
    right:-67px;
  }

  .block-photo-with-desc {
    margin: 3px 0;
  }

  .parent-hotspot-block {
    display: none;
    padding: 10px 15px 10px;
  }
  .parent-hotspot-block-mobile {
    display: block;
    opacity: 1;
    width: 80%;
    position: relative;
    background: #fff;
    margin: 0;
    z-index: 111111;
    top: -75px;
    left: 0;
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
  }

  .parent-hotspot-block-game {
    padding: 0px;
    border-radius: 15px;
    background: url(../../assets/gift.jpg) center / cover no-repeat;
  }

  .DisCElement-block {
    top: 20px;
  }

  .OldPriceElement-block {
    margin: 0;
  }

  .block-photo-with-desc-left {
    margin-bottom: 5px;
  }

} 

@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .parent-hotspot-block-game {
      height: 355px;
  }
}

@media screen 
and (device-width: 360px) 
and (device-height: 640px) 
and (-webkit-device-pixel-ratio: 4) 
and (orientation: portrait) {
  .parent-hotspot-block-game {
    height: 410px;
  }
}

@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {

    .parent-hotspot-block-game {
      height: 425px;
    }
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 
  .parent-hotspot-block-game {
      height: 375px;
  }
}

@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 
  .parent-hotspot-block-game {
    height: 425px;
  }
}

@media only screen 
and (min-device-width: 400px) 
and (max-device-width: 736px)
and (orientation: portrait) { 
  .parent-hotspot-block-game {
    height: 425px;
  }
}

@supports (-webkit-touch-callout: none) {
  .parent-hotspot-block-mobile {
    top: -75px;
    padding:17px 15px 13px;
    -webkit-text-size-adjust: none;
    touch-action: pan-x pan-y;
  }
  .parent-hotspot-logo-shop-src-mobile {
    margin-left:8%;
  }
  .parent-hotspot-block h1 {
    margin: 8px 0 2px;
  }

  .block-photo-with-desc-left {
    margin:10px 0 5px;
  }
}