@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/ProximaNova-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/ProximaNova-Extrabold.ttf);
    font-weight: 900;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/ProximaNova-Regular.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/ProximaNova-Semibold.ttf);
    font-weight: 500;
}

body {
    font-size: 16px;
}

p {
    margin-bottom: 0;
}

:root {
    --app-height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'ProximaNova', sans-serif;
    list-style: none;
}

a:hover {
    text-decoration: none;
}

.pnlm-about-msg,
.pnlm-about-msg a {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
}

.main-block {
    z-index: 50;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.no-content-block {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-break: break-all;
    width: 100%;
    height: 100vh;
    padding: 0;
    box-sizing: border-box;
    z-index: 1200;
}
.icon-no-found {
    font-size: 77px;
    color: #f1f1f1;
    margin: 20px 0;
}
.no-content-block h2 {
    font-weight: normal;
    color: #c5c5c5;
    font-size: 23px;
    text-transform: uppercase;
}
.no-content-block p {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: normal;
    word-break: break-all;
}

.parent-hotspot-block {
    text-align: center;
    gap: 2px;
    min-width: 285px;
    border-radius: 8px;
    padding: 20px 15px 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 0;
    bottom: -115px;
    margin-bottom: 100px;
    margin-left: -144px;
    box-sizing: border-box;

    -webkit-backface-visibility: hidden;

    opacity: 0;
    visibility: visible;

    pointer-events: auto;

    transform: scale(0.13);
    -o-transform: scale(0.13);
    -ms-transform: scale(0.13);
    -moz-transform: scale(0.13);
    -webkit-transform: scale(0.13);
}

.parent-hotspot-block-opengg {
    opacity: 1;
    visibility: visible;

    pointer-events: auto;

    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
}

.block-photo-with-desc {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
    justify-content: space-between;
}

.block-photo-with-desc-left {
    width: 40%;
    margin: 5px 0 0;
    height: 120px;
    border-radius: 15px;
    border: 1px solid #ced5e0;
}

.block-photo-with-desc-right {
    width: 60%;
    box-sizing: border-box;
    padding: 15px;
}

.block-photo-with-desc-right h5 {
    font-weight: normal;
    font-size: 10px;
    margin-bottom: 7px;
    font-weight: bold;
    color: #0a2463;
    word-break: break-all;
}

.parent-hotspot-block img {
    width: 22px;
    height: 22px;
}

.parent-hotspot-block a {
    width: 100%;
    height: 25px;
    background: #146fd1;
    border-radius: 10px;
    display: flex;
    margin: 10px auto 5px auto;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    font-weight: normal;
    color: #fff;
    text-decoration: none;
}

.parent-hotspot-block a:after {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
    background: transparent;
    position: absolute;
    top: 100%;
}

.parent-hotspot-block p {
    font-size: 16px;
    color: #0a2463;
    line-height: 21px;
    margin-top: 7px;
    font-weight: 900;
}

.parent-hotspot-logo-shop p {
    margin-top: 0;
}

.parent-hotspot-block h1 {
    font-size: 22px;
    color: #0a2463;
    line-height: 20px;
    margin: 1px 0 2px;
    font-weight: 900;
}

.parent-hotspot-block h1 b {
    color: #717fa0;
    font-size: 16px;
    font-weight: bold;
}

.pnlm-pointer {
    pointer-events: auto;
}

.pnlm-pointer:hover {
    z-index: 500;
    -webkit-backface-visibility: hidden;
}

.pnlm-pointer:hover .parent-hotspot-block {
    -webkit-backface-visibility: hidden;

    z-index: 111111;
    margin-bottom: 210px;
    opacity: 1;
    visibility: visible;

    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);

    transition: 0.1s all;
    -o-transition: 0.1s all;
    -ms-transition: 0.1s all;
    -webkit-transition: 0.1s all;
    -moz-transition: 0.1s all;
}

.plan-apartment-block-content {
    position: relative;
}

.plan-apartment-block {
    max-height: 90vh;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 900;
    display: table;
    border-radius: 5px;
    aspect-ratio: 1/1.5;
    width: 35%;
    display: flex;
    flex-direction: column;
}

.plan-apartment-block-content > a {
    width: 70px;
    height: 35px;
    display: block;
    position: absolute;
    z-index: 1000;
    top: -15px;
    right: -51px;
}

.plan-apartment-block > a img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.pnlm-ui > .pnlm-controls-container {
    right: 4px;
}

.plan-apartment-block-content > img {
    /* max-width: 250px; */
    /* max-height: 300px; */
    opacity: 0.6;
}

.dot-room-for-plan {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    z-index: 13;
    cursor: pointer;
}

.dot-room-for-plan-active img {
    filter: brightness(0) invert(1);
}

.dot-room-for-plan img {
    width: 100%;
    height: auto;
}

.dot-preview-block-for-plan {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.dot-preview-block-for-plan img {
    width: 100%;
    height: auto;
}

.parent-hotspot-logo-shop {
    justify-content: center;
    min-width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ced5e0;
}

.DisCElement-block {
    width: 40px;
    height: 40px;
    border-radius: 66%;
    position: absolute;
    top: 8px;
    right: 6px;
    background: red;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    align-items: center;
}

.OldPriceElement-block {
    margin: auto;
    position: relative;
    top: 0px;
    color: #939598;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    width: fit-content;
    font-family: 'ProximaNova-Extrabold';
}

.OldPriceElement-block:after {
    content: '';
    position: absolute;
    top: 50%;
    left: -2%;
    width: 104%;
    height: 1px;
    margin-top: -1px;
    background: red;
    z-index: 11;
    display: block;
}

.parent-hotspot-logo-shop p {
    font-size: 14px;
    color: #0a2463;
    font-weight: bold;
}

.parent-hotspot-logo-shop-src {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

img.parent-hotspot-point-icon {
    opacity: 1;
    transition: 0.2s all;
}

.MainDiv-hotspot-block:hover img.parent-hotspot-point-icon {
    opacity: 0;
}

.MainDiv-hotspot-block:after {
    content: '';
    opacity: 0;
    position: absolute;
    top: -15px;
    left: -7px;
    width: 40px;
    height: 2px;
    transform: scale(1) rotateZ(-90deg);
    transition: 0.2s all;
}

.MainDiv-hotspot-block:before {
    content: '';
    width: 90px;
    height: 70px;
    position: absolute;
    top: -41px;
    right: -33px;
}

.MainDiv-hotspot-block:hover:after {
    content: '';
    opacity: 1;
    transform: scale(1) rotateZ(-90deg);
    transition: 0.1s all;
}

img.parent-hotspot-point-icon-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    margin: -14px 0 0 -14px;
    transition: 0.2s all;
}

.MainDiv-hotspot-block:hover img.parent-hotspot-point-icon-hover {
    opacity: 1;
    transition: 0.2s all;
}

.block-photo-with-desc-right > p {
    font-weight: normal;
    font-size: 10px;
    margin: 2px 0 0 0;
    font-weight: bold;
    color: #0a2463;
    line-height: 11px;
}

.buttons-block-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: -57px;
    left: 0;
}

.buttons-block-card a,
.buttons-block-card button {
    display: flex;
    width: 100%;
    height: 50px;
    background: #146fd1;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    border: none;
    color: #fff;
    cursor: pointer;
}

.buttons-block-card button {
    display: grid;
    place-items: center;
}

.buttons-block-card p {
    display: flex;
    width: 100%;
    height: 60px;
    background: #146fd1;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.modal-mail-block {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100000000000;
    background: rgba(0, 0, 0, 0.88);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;

    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ms-transition: 0.2s all;
}

.modal-mail-block-content {
    width: auto;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-mail-block-content input {
    width: 300px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 0 15px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    outline: none;
    margin: 7px 0;
}

.modal-mail-block-content input[type='submit'] {
    width: 100%;
    border: none;
    background: #1977f3;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
}

.modal-mail-block-opened {
    opacity: 1;
    visibility: visible;

    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ms-transition: 0.2s all;
}

.modal-mail-block-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.buttons-block-card a:nth-child(1) {
    margin-right: 3px;
}

.buttons-block-card a:nth-child(2) {
    margin-left: 3px;
    line-height: 15px;
}

.buttons-block-card img {
    width: 30px;
    height: 30px;
    margin-right: 6px;
}

.buttons-block-card a.merchant-link {
    display: flex;
    flex-direction: column;
}

.overlay-mobile-card {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -999999;
    background: rgb(10 36 99 / 77%);
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.overlay-mobile-card-opened {
    opacity: 1;
    visibility: visible;
    z-index: 999999;

    transition: 0.2s all;
    -o-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
}

.parent-hotspot-logo-shop-mobile {
    align-items: center;
}

.parent-hotspot-logo-shop-src-mobile {
    margin-right: 20%;
    margin-left: 9%;
}

.overlay-mobile-card-back {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
}

.parent-hotspot-block-game {
    height: 360px;
    padding: 0px;
    border-radius: 15px;
    background: url(../../assets/gift.jpg) center / cover no-repeat;
}

.close-product-btn {
    top: 16px !important;
    right: 16px !important;
}

.OldPriceElement-block {
    text-align: center;
}

*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    background: #d6e3f4;
}
*::-webkit-scrollbar-thumb {
    background-color: #3f5b9e;
    border-radius: 20px;
}

*::-moz-scrollbar {
    width: 6px;
}
*::-moz-scrollbar-track {
    background: #d6e3f4;
}
*::-moz-scrollbar-thumb {
    background-color: #3f5b9e;
    border-radius: 20px;
}

*::-o-scrollbar {
    width: 6px;
}
*::-o-scrollbar-track {
    background: #d6e3f4;
}
*::-o-scrollbar-thumb {
    background-color: #3f5b9e;
    border-radius: 20px;
}

*::-ms-scrollbar {
    width: 6px;
}
*::-ms-scrollbar-track {
    background: #d6e3f4;
}
*::-ms-scrollbar-thumb {
    background-color: #3f5b9e;
    border-radius: 20px;
}

@media screen and (max-width: 600px) {
    .plan-apartment-block {
        width: 50%;
    }
}

@media screen and (min-width: 1100px) {
    .plan-apartment-block {
        width: 20%;
    }
}

@media screen and (min-width: 992px) {
    .close-product-btn {
        width: 34px !important;
        height: 34px !important;
        top: 11px !important;
    }
}
