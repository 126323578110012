.productPoint {
    width: auto;
    height: auto;
    position: relative;
}

/*
.productPoint * {
    pointer-events: none;
}
*/

.productPointSnow {
    width: 100%;
    height: 20px;
    background: url(https://api.logovo.kz/uploads/snow-2.png) center / contain;
    position: absolute;
    top: -2px;
    left: 0;
}

.productPointIcon {
    width: 30px;
    height: 30px;
    z-index: 200;
    background: url(https://api.logovo.kz/uploads/point.svg) center / contain no-repeat;
    position: relative;

    transform: scale(1);
    transition: 0.16s all;
}

.productPoint:hover .productPointIcon {
    background: url(https://api.logovo.kz/uploads/new-point.svg) center / contain no-repeat;

    transform: scale(1.7);
    transition: 0.32s all;
}

.overlaybackHover {
    display: block;
    position: absolute;
    visibility: hidden;
    top: -15px;
    left: 16px;
    width: 80px;
    height: 85px;
}

.productPoint:hover .overlaybackHover {
    visibility: visible;
}

.productPointWrapper {
    position: absolute;
    width: 230px;
    height: 0;
    z-index: 200;
    cursor: pointer;
    border-radius: 5px;
    top: -10px;
    left: calc(100% + 14px);
    overflow: hidden;

    transition: 0.3s all;
}

.productPoint:hover .productPointWrapper {
    top: -30px;
    height: 320px;

    transition: 0.3s all;
}
.productPointContent {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    position: relative;
}

.productPointPhoto {
    width: 100%;
    height: 160px;
    margin: 10px 0;
    border-radius: 7px;
}

.productPointContent h5 {
    white-space: nowrap;
    position: relative;
    left: 0;
    color: #0a2463;
    margin: 0;
}

.productPointContent h2 {
    white-space: nowrap;
    position: relative;
    left: 0;
    font-size: 18px;
    color: #0a2463;
    margin: 0;
}

.productPointContent p {
    color: #0a2463;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}

.productPointWrapper span {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    background-color: #146fd1;
    border-radius: 5px;
    color: #fff;
    margin-top: 5px;
}

.product_cnv {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
}

@media screen and (max-width: 968px) {
    .productPoint:hover .productPointWrapper {
        top: 0;
        height: 0;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);

        transition: 0.3s all;
    }

    .overlaybackHover {
        display: none;
    }
}

@supports (-webkit-touch-callout: none) {
    .productPoint:hover .productPointIcon {
        background: url(../../../assets/point.svg) center / contain no-repeat;
        transform: scale(1.2);
        transition: 0.32s all;
    }
}
